import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { NgxI18nService } from '@studiohyperdrive/ngx-i18n';

import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { LayoutContainerComponent } from '@cjm/shared/ui/common';
import { transformJsonToObject, AdviceCardComponent } from '@cjm/v-loket/shared';

import { AdvicePageRecommendationEntity } from '../../../data';
import { BackgroundVariants } from '../../interfaces';

@Component({
	selector: 'vloket-advice-detail-page-recommended-block',
	templateUrl: './advice-detail-page-recommended-block.component.html',
	styleUrls: ['./advice-detail-page-recommended-block.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [LayoutContainerComponent, AdviceCardComponent]
})
export class AdviceDetailPageRecommendedBlockComponent {
	@HostBinding('class.c-advice-detail-page-recommended-block') private readonly rootClass: boolean = true;

	@Input({
		required: true,
		transform: (value: string) => {
			return transformJsonToObject<AdvicePageRecommendationEntity>(value);
		}
	})
	public data: AdvicePageRecommendationEntity;

	public readonly backgroundVariants: typeof BackgroundVariants = BackgroundVariants;
	public readonly appRoutePaths: typeof VLoketAppRoutePaths = VLoketAppRoutePaths;

	constructor(public readonly i18nService: NgxI18nService) {}
}
