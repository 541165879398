import { importProvidersFrom } from '@angular/core';
import { Routes } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { provideWithTranslations, NgxI18nTranslationLoaderGuard } from '@studiohyperdrive/ngx-i18n';

import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { StoreSlice } from '@cjm/shared/store';
import { AdvicePagesApiService, advicePagesReducers } from '@cjm/v-loket/repositories';

import { adviceDetailPageResolver } from '../data';
import { AdvicePageApiService } from '../data/services';
import { reducers } from '../data/store';
import { AdviceI18nModuleTranslationLoader } from '../i18n/loaders';

import { AdviceDetailPageComponent, AdviceOverviewPageComponent } from './pages';

export const AdviceRoutes: Routes = [
	provideWithTranslations(
		{
			path: VLoketAppRoutePaths.AdviceDetail,
			component: AdviceDetailPageComponent,
			canActivate: [NgxI18nTranslationLoaderGuard],
			resolve: {
				data: adviceDetailPageResolver
			},
			providers: [
				// Service
				AdvicePageApiService,
				// Store
				importProvidersFrom(StoreModule.forFeature(StoreSlice.Advice, reducers))
			]
		},
		AdviceI18nModuleTranslationLoader
	),
	provideWithTranslations(
		{
			path: VLoketAppRoutePaths.Empty,
			component: AdviceOverviewPageComponent,
			canActivate: [NgxI18nTranslationLoaderGuard],
			providers: [
				// Service
				AdvicePagesApiService,
				// Store
				importProvidersFrom(StoreModule.forFeature(StoreSlice.Advices, advicePagesReducers))
			]
		},
		AdviceI18nModuleTranslationLoader
	)
];
