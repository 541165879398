import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FaIconComponent, LayoutContainerComponent } from '@cjm/shared/ui/common';
import { AdvicePageKeywordEntity, AdvicePageThemeTagComponent } from '@cjm/v-loket/shared';

import { I18nKeys } from '../../../i18n';

@Component({
	selector: 'vloket-advice-detail-page-header',
	templateUrl: './advice-detail-page-header.component.html',
	styleUrls: ['./advice-detail-page-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [LayoutContainerComponent, AdvicePageThemeTagComponent, FaIconComponent, DatePipe, TranslateModule]
})
export class AdviceDetailPageHeaderComponent {
	@HostBinding('class.c-advice-detail-page-header-wrapper') private readonly rootClass: boolean = true;

	@Input({ required: true }) public pageTitle: string;
	@Input({ required: true }) public heroImage: string;
	@Input() public keywords: AdvicePageKeywordEntity[] = [];
	@Input() public lastUpdate: string;
	@Input() public minReadingTime: string;

	public readonly adviceI18nKeys: typeof I18nKeys = I18nKeys;
}
