import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgxI18nService } from '@studiohyperdrive/ngx-i18n';
import { StoreService, dispatchDataToStore } from '@studiohyperdrive/ngx-store';
import {
	ObservableArray,
	ObservableBoolean,
	ObservableNumber,
	ObservableRecord,
	validateContent
} from '@studiohyperdrive/rxjs-utils';
import { BehaviorSubject, Observable, first, map, of, shareReplay, take, tap, filter } from 'rxjs';

import { FacetEntity, FacetFilter, IndexedPagination } from '@cjm/shared/types';
import { SnackBarService, catchWithSnackBar } from '@cjm/shared/ui/toast';
import {
	AdvicePagesApiService,
	advicePageMapper,
	advicePagesActions,
	advicePagesSelectors
} from '@cjm/v-loket/repositories';
import { AdviceDetailPageEntity, AdvicePagesFilter, I18nKeys, parseFacetMap } from '@cjm/v-loket/shared';

@Injectable()
export class AdviceOverviewPageFacade extends StoreService implements OnDestroy {
	// Advice Pages
	public readonly adviceOverviewPages$: ObservableArray<AdviceDetailPageEntity> = this.selectFromStore<
		AdviceDetailPageEntity[]
	>(advicePagesSelectors.advicePages);
	public readonly adviceOverviewLoading$: ObservableBoolean = this.selectLoadingFromStore(
		advicePagesSelectors.advicePages
	);
	public readonly adviceOverviewError$: ObservableBoolean = this.selectErrorFromStore(
		advicePagesSelectors.advicePages
	);
	public readonly adviceOverviewPagination$: Observable<IndexedPagination> = this.selectFromStore<IndexedPagination>(
		advicePagesSelectors.advicePagination
	).pipe(shareReplay());
	public readonly advicePagesCountTotal$: ObservableNumber = this.advicePagesApiService.getAdvicePages().pipe(
		first(),
		map(({ pagination }) => pagination?.totalAmount)
	);
	public readonly advicePagesCountSubtotal$: ObservableNumber = this.adviceOverviewPagination$.pipe(
		map((pagination: IndexedPagination) => pagination?.totalAmount)
	);

	// Facets (filters)
	public readonly facets$: ObservableArray<FacetEntity> = this.selectFromStore<FacetEntity[]>(
		advicePagesSelectors.adviceFacets
	).pipe(
		filter((facets: FacetEntity[]) => Array.isArray(facets) && facets.length > 0),
		tap((facets: FacetEntity[]) => this.facetKeyValueMap$.next(parseFacetMap(facets)))
	);
	public readonly facetKeyValueMap$: BehaviorSubject<Record<string, string>> = new BehaviorSubject<
		Record<string, string>
	>({});
	public readonly facetMap$: ObservableRecord<string, string> = this.facetKeyValueMap$.asObservable();
	private readonly i18nKeys: typeof I18nKeys = I18nKeys;

	constructor(
		protected readonly store: Store,
		private readonly advicePagesApiService: AdvicePagesApiService,
		private readonly snackBarService: SnackBarService,
		private readonly i18nService: NgxI18nService
	) {
		super(store);
	}

	public ngOnDestroy(): void {
		this.clearAdviceOverviewPageItems().pipe(first()).subscribe();
		this.store.dispatch(advicePagesActions.advicePages.set({ payload: [] }));
	}

	/**
	 * getAdvicePages
	 *
	 * The getAdvicePages method will fetch the requested page through the API and store the data.
	 *
	 * @param filters
	 * @param dispatchType
	 *
	 * @returns ObservableBoolean
	 */
	public getAdvicePages(filters: AdvicePagesFilter = {}, dispatchType: 'set' | 'add' = 'set'): ObservableBoolean {
		return dispatchDataToStore<AdviceDetailPageEntity[]>(
			advicePagesActions.advicePages,
			this.advicePagesApiService.getAdvicePages(filters).pipe(
				// Validate if the response has content
				validateContent({ strict: true }),
				catchWithSnackBar(
					this.snackBarService,
					{
						title: this.i18nService.getTranslation(this.i18nKeys.SnackBars.ErrorTitle)
					},
					'throw'
				),
				// Map the data to fit the required format
				map(({ pages, pagination }): AdviceDetailPageEntity[] => {
					this.store.dispatch(advicePagesActions.advicePagination.set({ payload: pagination }));

					return advicePageMapper(pages);
				})
			),
			this.store,
			dispatchType
		).pipe(
			take(1),
			// Map to a boolean to avoid subscribing to the data
			map(() => true)
		);
	}

	/**
	 * Fetch the facets from the api and dispatch them to the store
	 *
	 * @param filters - The selected facets
	 * @returns ObservableBoolean
	 */
	public getFacets(filters: FacetFilter = {}): ObservableBoolean {
		return dispatchDataToStore<FacetEntity[]>(
			advicePagesActions.adviceFacets,
			this.advicePagesApiService.getFacets(filters),
			this.store
		).pipe(
			take(1),
			// Map to a boolean to avoid subscribing to the data
			map(() => true)
		);
	}

	/**
	 * clearAdviceOverviewPageItems
	 *
	 * The clearAdviceOverviewPageItems method will clear the current advice pages from store.
	 *
	 * @returns ObservableBoolean
	 */
	public clearAdviceOverviewPageItems(): ObservableBoolean {
		return dispatchDataToStore(advicePagesActions.advicePages, of([]), this.store).pipe(map(() => true));
	}
}
