import { NgTemplateOutlet, AsyncPipe, KeyValuePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxI18nService } from '@studiohyperdrive/ngx-i18n';
import { ObservableArray, ObservableBoolean, ObservableNumber, ObservableRecord } from '@studiohyperdrive/rxjs-utils';
import { isEmpty } from 'lodash';
import { Observable, distinctUntilChanged, map, switchMap, takeUntil, tap, filter } from 'rxjs';

import { MetaService } from '@cjm/shared/core';
import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { MediaQueryMin, MediaQueryMax, OnDestroyComponent, FacetEntity, IndexedPagination } from '@cjm/shared/types';
import {
	ButtonClasses,
	AlertType,
	ButtonComponent,
	CJMContentComponent,
	ResultsComponent,
	FaIconComponent,
	LayoutContainerComponent,
	PagerComponent
} from '@cjm/shared/ui/common';
import { MediaQueryDirective } from '@cjm/shared/ui/device';
import { generateSnackbarConfig, SnackBarComponent, SnackBarService } from '@cjm/shared/ui/toast';
import { UserService } from '@cjm/shared/user';
import {
	AdviceDetailPageEntity,
	AdvicePagesFilter,
	FilterBarForm,
	FilterSelection,
	AdviceCardComponent,
	FilterButtonsBarComponent,
	PageHeaderComponent
} from '@cjm/v-loket/shared';

import { AdviceOverviewPageFacade, AdvicePageBackgroundVariant } from '../../../data';
import { I18nKeys } from '../../../i18n';

@Component({
	selector: 'vloket-advices-page',
	templateUrl: './advice-overview-page.component.html',
	styleUrls: ['./advice-overview-page.component.scss'],
	providers: [AdviceOverviewPageFacade],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		PageHeaderComponent,
		LayoutContainerComponent,
		MediaQueryDirective,
		NgTemplateOutlet,
		ButtonComponent,
		ResultsComponent,
		FaIconComponent,
		CJMContentComponent,
		AdviceCardComponent,
		PagerComponent,
		FilterButtonsBarComponent,
		ReactiveFormsModule,
		AsyncPipe,
		KeyValuePipe,
		TranslateModule
	]
})
export class AdviceOverviewPageComponent extends OnDestroyComponent implements OnInit {
	@HostBinding('class.p-advice-overview') private readonly rootClass: boolean = true;

	// Pages
	public readonly adviceOverviewPages$: ObservableArray<AdviceDetailPageEntity> =
		this.adviceOverviewPageFacade.adviceOverviewPages$.pipe(
			tap(
				// Page data is stringified to be able to pass it on to the web-component data property
				(data) =>
					(this.pages = JSON.stringify({
						type: '',
						title: '',
						variant: AdvicePageBackgroundVariant.Gray,
						pages: data
					}))
			)
		);
	public readonly adviceOverviewLoading$: ObservableBoolean = this.adviceOverviewPageFacade.adviceOverviewLoading$;
	public readonly adviceOverviewError$: ObservableBoolean = this.adviceOverviewPageFacade.adviceOverviewError$;

	// Pagination
	public readonly advicePagesPagination$: Observable<IndexedPagination> =
		this.adviceOverviewPageFacade.adviceOverviewPagination$;
	public readonly advicePagesTotalCount$: ObservableNumber = this.adviceOverviewPageFacade.advicePagesCountTotal$;
	public readonly advicePagesCountSubtotal$: ObservableNumber =
		this.adviceOverviewPageFacade.advicePagesCountSubtotal$;

	// Facets
	public readonly facets$: ObservableArray<FacetEntity> = this.adviceOverviewPageFacade.facets$;
	public readonly facetMap$: ObservableRecord<string, string> = this.adviceOverviewPageFacade.facetMap$;

	public readonly appRoutePaths: typeof VLoketAppRoutePaths = VLoketAppRoutePaths;
	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;
	public readonly mediaQueryMin: typeof MediaQueryMin = MediaQueryMin;
	public readonly mediaQueryMax: typeof MediaQueryMax = MediaQueryMax;
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
	public readonly searchControl: FormControl<string> = new FormControl<string>('');
	public readonly facetControl: FormControl<FilterBarForm> = new FormControl<FilterBarForm>(null);
	public selectedFilters: FilterSelection[] = [];
	public showFilterBar: boolean = false;
	public pages: string;
	public headerImg: string = 'fietsherstelling.webp';

	constructor(
		private adviceOverviewPageFacade: AdviceOverviewPageFacade,
		private readonly metaService: MetaService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly router: Router,
		private readonly userService: UserService,
		private readonly snackBarService: SnackBarService,
		public readonly i18nService: NgxI18nService
	) {
		super();
	}

	public ngOnInit(): void {
		this.adviceOverviewPageFacade.getFacets().pipe(takeUntil(this.destroyed$)).subscribe();

		this.metaService.updateMetaData({
			title: this.i18nService.getTranslation(this.i18nKeys.PageTitles.VLoket.Advice),
			description: this.i18nService.getTranslation(this.i18nKeys.AdviceOverview.Intro),
			pageUrl: this.router.url,
			imageUrl: { path: this.headerImg }
		});

		this.userService
			.isNonRegistrableEA()
			.pipe(
				filter((isNonRegistrableEA: boolean) => isNonRegistrableEA),
				tap(() => {
					this.snackBarService.openFromComponent(
						SnackBarComponent,
						generateSnackbarConfig({
							id: 'non-registrable-ea-advice-notice',
							title: this.i18nService.getTranslation(this.i18nKeys.SnackBars.NoticeTitle),
							message: this.i18nService.getTranslation(
								this.i18nKeys.AdviceOverview.Notices.UnregistrableEa
							),
							type: AlertType.Info,
							persistClosure: 'session'
						})
					);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.facetControl.valueChanges
			.pipe(
				distinctUntilChanged(),
				tap((values: FilterBarForm) => this.handleFacetChanges(values)),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.activatedRoute.queryParams
			.pipe(
				map((queryParams: Params) => ({
					...queryParams,
					...(queryParams?.filters ? { filters: JSON.parse(queryParams.filters) } : {})
				})),
				tap((queryParams: AdvicePagesFilter) => {
					// Denis: Facet filter handling:
					this.processFilterQueryParam(queryParams);
				}),
				switchMap((queryParams: AdvicePagesFilter) =>
					this.adviceOverviewPageFacade.getAdvicePages(queryParams)
				),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/**
	 * handleFilterClear
	 *
	 * The handleFilterClear method will clear a selected filter through the facetControl.
	 *
	 * @param selection
	 */
	public handleFilterClear(selection?: FilterSelection): void {
		if (!selection) {
			this.facetControl.patchValue({});

			return;
		}

		const facetValue = this.facetControl.getRawValue();
		delete facetValue[selection.parentId][selection.id];

		this.facetControl.patchValue(facetValue);
	}

	/**
	 * handleFilterToggle
	 *
	 * The handleFilterToggle method will set the showFilterBar boolean to
	 * show/hide the filter bar on mobile.
	 */
	public handleFilterToggle(): void {
		this.showFilterBar = !this.showFilterBar;
	}

	/**
	 * handlePageChange
	 *
	 * The handlePageChange method will move to the next page when requested.
	 *
	 * @param page
	 */
	public handlePageChange(page: number = 1): void {
		if (!page) {
			return;
		}

		this.router.navigate([], {
			// Denis: Navigate to the current route to prevent a page reload.
			relativeTo: this.activatedRoute,
			// Denis: Only set the search query param.
			queryParams: { index: page },
			// Denis: Set the query param handling to merge to avoid overwriting the existing filters.
			queryParamsHandling: 'merge',
			// Denis: use the replaceUrl to prevent the browser from adding a new history entry.
			replaceUrl: true
		});
	}

	/**
	 * handleFacetChanges
	 *
	 * The handleFacetChanges will filter out active facet options
	 * and set the selected filters to the queryparams.
	 *
	 * @param selectedValues
	 */
	private handleFacetChanges(selectedValues: FilterBarForm): void {
		if (!selectedValues) {
			return;
		}

		const activeFilters = Object.keys(selectedValues).reduce((acc, facetId: string) => {
			const activeOptions = Object.keys(selectedValues[facetId]).filter(
				(itemId: string) => selectedValues[facetId][itemId]
			);

			return activeOptions.length > 0
				? {
						...acc,
						[facetId]: activeOptions
					}
				: acc;
		}, {});

		this.router.navigate([], {
			// Denis: Navigate to the current route to prevent a page reload.
			relativeTo: this.activatedRoute,
			// Denis: Only set the facet params & reset the index.
			queryParams: { filters: JSON.stringify(activeFilters), index: 1 },
			// Denis: Set the query param handling to merge to avoid overwriting the existing filters.
			queryParamsHandling: 'merge',
			// Denis: use the replaceUrl to prevent the browser from adding a new history entry.
			replaceUrl: true
		});
	}

	/**
	 * processFilterQueryParam
	 *
	 * The processFilterQueryParam method will handle a changed filter query param.
	 *
	 * @param queryParams
	 * @returns void
	 */
	private processFilterQueryParam(queryParams: AdvicePagesFilter): void {
		this.selectedFilters = [];

		const filters = isEmpty(queryParams.filters) ? {} : queryParams.filters;
		const selectedFacetItems = Object.keys(filters).reduce(
			(accFacets, facetId: string) => ({
				...accFacets,
				[facetId]: filters[facetId].reduce((accItems, itemId: string) => {
					// Denis: set the selectedFilters as well
					this.selectedFilters.push({
						parentId: facetId,
						id: itemId
					});

					return {
						...accItems,
						[itemId]: true
					};
				}, {})
			}),
			{}
		);

		this.facetControl.patchValue(selectedFacetItems, { emitEvent: false });
	}
}
